import "../../vendor/wire-elements/modal/resources/js/modal";
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import Clipboard from "@ryangjchandler/alpine-clipboard";
import CopyToClipboard from "./components/CopyToClipboard";
import Mockup from "./components/Mockup";
import DropzoneUploader from "./components/DropzoneUploader";
import UppyUploader from "./components/UppyUploader";

// import static assets for versioning
import.meta.glob('../images/**')

Alpine.data('CopyToClipboard', CopyToClipboard)
Alpine.data('monkey', Mockup)
Alpine.data('dropzoneUploader', DropzoneUploader)
Alpine.data('uppyUploader', UppyUploader)
Alpine.plugin(focus)
Alpine.plugin(Clipboard)

window.Alpine = Alpine

Alpine.start()
